/*===================== 	Premery bg color =====================*/

:root {
	--primary: #4c1864;
	--sc-primary: #4d0a91;
	--secondary: #f7b205;
}
::selection{
	color: #fff;
	background-color: #4c1864;
}

/* Hex */

.bg-primary,
.btn,
.comments-area .comment-form p input[type="submit"],
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.feature-filters .active>[class*="btn"],
.list-num-count>li:before,
.pagination-bx.primary .pagination>li>a,
.pagination-bx.primary .pagination>li>span,
button.back-to-top,
.rev-btn {
	background-color: #4c1864;
}


/*Hex color :hover */

.btn:active,
.btn:hover,
.btn:focus,
.active>.btn,
.bg-primary-dark,
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus,
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus,
.sidenav .closebtn:hover,
.sidenav .closebtn:focus,
.owl-btn-1.primary .owl-prev:hover,
.owl-btn-1.primary .owl-next:hover,
.owl-btn-2.primary .owl-prev:hover,
.owl-btn-2.primary .owl-next:hover,
.pagination-bx.primary .pagination>li>a:hover,
.pagination-bx.primary .pagination>li>span:hover,
.pagination-bx.primary .pagination>li.active>span,
.pagination-bx.primary .pagination>li.active>a{
	background-color: #4d0a91;
}
.services-bx:hover:after,
.testimonial-bx.style1,
.subscribe-box{
	background: #4d0a91;
}

/*===================== 	Premery text color =====================*/

a,
.text-primary,
.primary li:before,
.menu-links .nav>li.active>a,
.menu-links .nav>li:hover>a,
.menu-links .nav>li .mega-menu>li ul a:hover,
.menu-links .nav>li .sub-menu li:hover>a,
footer a:active,
footer a:focus,
footer a:hover,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a,
.testimonial-1 .testimonial-position,
.acod-head a:after,
.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover,
.ttr-tabs .nav-tabs>li>a i,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.btn.outline,
.btn-link:hover,
.service-no{
	color: #4c1864;
}


/*===================== 	Secondery bg color =====================*/

.bg-secondry {
	background-color: #2d3239;
}


/*===================== 	Secondery text color =====================*/

.text-secondry {
	color: #2d3239;
}

.ovpr-light:after,
.ovpr-middle:after,
.ovpr-dark:after,
.ovpr:after,
.bg-primary {
	background-color: #4c1864;
}

.footer-top,
.footer-bottom {
	background-color: #121921;
}

.ovbl-middle::after,
.ovbl-light::after,
.ovbl-dark::after {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#100018+0,26006c+50,110038+100 */
	background: #100018;
	/* Old browsers */
	background: -moz-linear-gradient(45deg, #100018 0%, #26006c 50%, #110038 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #100018 0%, #26006c 50%, #110038 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #100018 0%, #26006c 50%, #110038 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#100018', endColorstr='#110038', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}

.ttr-notify-header::after,
.widget-bg1,
.ttr-search-bar,
.ttr-header,
.ovpr-dark:after,
.pricingtable-main,
.account-head:after {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c1864+0,3f189a+100 */
	background: #4c1864;
	/* Old browsers */
	background: -moz-linear-gradient(45deg, #4c1864 0%, #3f189a 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(45deg, #4c1864 0%, #3f189a 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(45deg, #4c1864 0%, #3f189a 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c1864', endColorstr='#3f189a', GradientType=1);
	/* IE6-9 fallback on horizontal gradient */
}


/* Secondry Color */

.photos-bx .photos-info-bx,
.btn.outline:hover,
.course-info-list ul li a.active,
.course-info-list ul li a:hover,
.btn,
.profile-tabnav .nav .nav-item a.active,
button.back-to-top,
.upcoming-event-carousel.owl-btn-1 .slick-arrow,
.courses-carousel .slick-arrow,
.courses-carousel .slick-arrow,
.recent-news-carousel .slick-arrow,
.testimonial-carousel .slick-arrow {
	background-color: #f7b205;
	color: #000;
}

.footer_widget ul li a:hover,
[class*="ovbl-"] .counter-style-1 .counter-text,
.header-transparent .secondary-menu .btn-link:hover,
.header-transparent .menu-links .nav>li:hover>a,
.header-transparent .menu-links .nav>li.active>a {
	color: #f7b205;
}

.course-info-list ul li a.active:hover,
.course-info-list ul li a:hover,
.btn:hover,
.profile-tabnav .nav .nav-item a.active:hover,
button.back-to-top:hover,
.upcoming-event-carousel.owl-btn-1 .slick-arrow:hover,
.courses-carousel .slick-arrow:hover,
.recent-news-carousel .slick-arrow:hover,
.testimonial-carousel .slick-arrow:hover {
	background-color: #4c1864;
	color: #fff;
}

.heading-bx.text-center .title-head ,
.heading-bx.left .title-head,
.widget-title{
	border-color: #f7b205;
}

.bg-primary {
	background-color: #4c1864!important;
}
.text-primary{
	color: #4c1864!important;
}
.text-important{
	color: rgb(230, 40, 141)!important;
}